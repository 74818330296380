import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { createWtxAuthInstance, SetupProvider, WtxColors, CenteredDiv } from '@wavetronix/common-components'
import MainPage from './Components/MainPage'
import generateEnv from './env/generateEnv.js'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)

  root.render(
    <SetupProvider
      env={env}
      msalInstance={msalInstance}
      allowedRoles={['Expanse Admin', 'Licensing Admin', 'License Assignment Admin']}>
      <MainPage />
    </SetupProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
