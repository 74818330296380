import { MenuAppBar } from '@wavetronix/common-components'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { env } from '../index.js'
import Dashboard from './Dashboard'
import UserDetailView from './UserDetailView'
import UserDetailViewV2 from './UserDetailViewV2'
import version from '../env/version.json'
function MainPage() {
  return (
    <>
      <MenuAppBar env={env} appHeader='License Assignment' />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Dashboard />} exact />
          <Route path='/userdeprecated' element={<UserDetailView />} exact />
          <Route path='/user' element={<UserDetailViewV2 />} exact />
          <Route path='/version' element={<div>{version.version}</div>} exact />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default MainPage
