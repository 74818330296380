function getDeviceName(id) {
  switch (id) {
    case 'CING':
      return 'Arc Firmware'
    case 'HDA':
      return 'XP20 Firmware'
    case 'LEGACY_HD':
      return 'HD Firmware'
    case 'LEGACY_MATRIX':
      return 'Matrix Firmware'
    case 'DESKTOP':
      return 'Expanse UI'
    default:
      return id
  }
}

export default getDeviceName
