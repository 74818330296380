import { useMsal } from '@azure/msal-react'
import { AddOutlined } from '@mui/icons-material'
import {
  acquireAccessToken,
  CustomDataGrid,
  ErrorMessage,
  RegularButton,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { Switch } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { env } from '../index.js'
import compareAlphabetically from '../utils/compareAlphabetically'
import handleAxiosError from '../utils/handleAxiosError'
import LicenseModal from './LicenseModal'
import LicenseV2Modal from './LicenseV2Modal'
import UsersFilterDrawer, { DEFAULT_USERS_FILTER, filterUsers } from './UsersFilterDrawer'

export default function Dashboard() {
  const navigate = useNavigate()
  const columns = [
    {
      field: 'givenName',
      headerName: 'First Name',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'surname',
      headerName: 'Last Name',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'company',
      headerName: 'Company',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      sortComparator: compareAlphabetically,
      disableColumnMenu: true,
      flex: 1
    }
  ]

  const [searchParams, setSearchParams] = useSearchParams()

  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [filter, setFilter] = useState(DEFAULT_USERS_FILTER)
  const [useDeprecated, setUseDeprecated] = useState(searchParams.get('deprecated') ? true : false)

  const [state, setState] = useState({
    licenseModalVisible: false,
    applyingFilter: false
  })
  const getUsers = async () => {
    let token = await acquireAccessToken(instance, accounts, env)
    return await axios
      .get(`${env.urls.gatekeeperURL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        return res.data
      })
      .catch(e => handleAxiosError(e, enqueueSnackbar))
  }

  const { isLoading, data, refetch, error } = useQuery({ queryKey: ['users'], queryFn: getUsers })

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <>
      <PersistantFilterDiv
        resetFilter={() => setFilter(DEFAULT_USERS_FILTER)}
        drawer={<UsersFilterDrawer filter={filter} setFilter={setFilter} />}
        page={
          <>
            {useDeprecated === false ? (
              <LicenseV2Modal
                onCancel={() => {
                  refetch()
                  setState(s => ({ ...s, licenseModalVisible: false }))
                }}
                visible={state.licenseModalVisible}
                users={data !== null && data !== undefined ? data : []}
                loading={isLoading}
                isDetailView={false}
              />
            ) : (
              <LicenseModal
                onCancel={() => {
                  refetch()
                  setState(s => ({ ...s, licenseModalVisible: false }))
                }}
                visible={state.licenseModalVisible}
                users={data !== null && data !== undefined ? data : []}
                loading={isLoading}
                isDetailView={false}
              />
            )}

            <div style={{ width: '100%', margin: '10px', fontStyle: 'italic' }}>
              <Switch
                color='primary'
                checked={useDeprecated}
                onChange={e => {
                  setUseDeprecated(e.target.checked)
                  setSearchParams(`deprecated=${e.target.checked}`)
                }}
              />
              Use License V1 (Deprecated)
            </div>
            <RegularButton
              style={{ margin: '15px 0px 15px 15px' }}
              startIcon={<AddOutlined />}
              onClick={() => setState(s => ({ ...s, licenseModalVisible: true }))}>
              Assign License
            </RegularButton>

            <div style={{ width: '100%', display: 'block', paddingLeft: '15px' }}>
              <CustomDataGrid
                rows={
                  data
                    ? filterUsers(filter, data).map(u => {
                        return { ...u, key: u.id }
                      })
                    : []
                }
                columns={columns}
                loading={isLoading}
                onRowClick={user =>
                  useDeprecated === true ? navigate(`/userdeprecated?id=${user.id}`) : navigate(`/user?id=${user.id}`)
                }
                cursor='pointer'
              />
            </div>
          </>
        }
      />
    </>
  )
}
