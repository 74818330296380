import { Switch, TextField } from '@mui/material'

export const DEFAULT_USERS_FILTER = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  showInternal: true
}

const nullCheck = s => (s ? s : '')

const classes = {
  metaData: {
    width: '100%',
    marginTop: '15px'
  }
}

export const filterUsers = (filter, users) =>
  users.filter(
    u =>
      nullCheck(u.givenName).toLowerCase().includes(filter.firstName.toLowerCase()) &&
      nullCheck(u.surname).toLowerCase().includes(filter.lastName.toLowerCase()) &&
      nullCheck(u.email).toLowerCase().includes(filter.email.toLowerCase()) &&
      nullCheck(u.company).toLowerCase().includes(filter.companyName.toLowerCase()) &&
      (u.type === 'ExternalUser' || filter.showInternal)
  )

export default function UsersFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))
  return (
    <>
      <TextField
        label='First Name'
        size='small'
        variant='outlined'
        style={{ width: '100%' }}
        onChange={e => handleChange('firstName', e.target.value)}
        value={filter.firstName}
      />

      <TextField
        label='Last Name'
        size='small'
        variant='outlined'
        style={classes.metaData}
        onChange={e => handleChange('lastName', e.target.value)}
        value={filter.lastName}
      />

      <TextField
        label='Email'
        size='small'
        variant='outlined'
        style={classes.metaData}
        onChange={e => handleChange('email', e.target.value)}
        value={filter.email}
      />

      <TextField
        label='Company'
        size='small'
        variant='outlined'
        style={classes.metaData}
        onChange={e => handleChange('companyName', e.target.value)}
        value={filter.companyName}
      />

      <div style={classes.metaData}>
        <Switch color='primary' checked={filter.showInternal} onChange={e => handleChange('showInternal', e.target.checked)} />
        Show Internal
      </div>
    </>
  )
}
